exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acknowledgements-js": () => import("./../../../src/pages/acknowledgements.js" /* webpackChunkName: "component---src-pages-acknowledgements-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin/[...].js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-2024-04-15-launch-technology-preview-1-sprucely-insights-dashboards-platform-js": () => import("./../../../src/pages/news/2024-04-15-launch-technology-preview-1-sprucely-insights-dashboards-platform.js" /* webpackChunkName: "component---src-pages-news-2024-04-15-launch-technology-preview-1-sprucely-insights-dashboards-platform-js" */),
  "component---src-pages-news-2024-07-01-launch-microsoft-powerpoint-addin-for-interactive-dashboards-with-sprucely-io-js": () => import("./../../../src/pages/news/2024-07-01-launch-microsoft-powerpoint-addin-for-interactive-dashboards-with-sprucely-io.js" /* webpackChunkName: "component---src-pages-news-2024-07-01-launch-microsoft-powerpoint-addin-for-interactive-dashboards-with-sprucely-io-js" */),
  "component---src-pages-news-2024-07-16-how-to-add-interactive-dashboards-and-multi-dimensional-charts-with-crossfiltering-in-powerpoint-ppt-presentations-js": () => import("./../../../src/pages/news/2024-07-16-how-to-add-interactive-dashboards-and-multi-dimensional-charts-with-crossfiltering-in-powerpoint-ppt-presentations.js" /* webpackChunkName: "component---src-pages-news-2024-07-16-how-to-add-interactive-dashboards-and-multi-dimensional-charts-with-crossfiltering-in-powerpoint-ppt-presentations-js" */),
  "component---src-pages-news-2024-09-18-launch-google-docs-and-slides-addon-for-interactive-dashboards-with-sprucely-io-js": () => import("./../../../src/pages/news/2024-09-18-launch-google-docs-and-slides-addon-for-interactive-dashboards-with-sprucely-io.js" /* webpackChunkName: "component---src-pages-news-2024-09-18-launch-google-docs-and-slides-addon-for-interactive-dashboards-with-sprucely-io-js" */),
  "component---src-pages-news-2024-10-08-color-themes-support-for-sprucely-io-js": () => import("./../../../src/pages/news/2024-10-08-color-themes-support-for-sprucely-io.js" /* webpackChunkName: "component---src-pages-news-2024-10-08-color-themes-support-for-sprucely-io-js" */),
  "component---src-pages-news-2024-12-17-chart-wizard-for-sprucely-io-js": () => import("./../../../src/pages/news/2024-12-17-chart-wizard-for-sprucely-io.js" /* webpackChunkName: "component---src-pages-news-2024-12-17-chart-wizard-for-sprucely-io-js" */),
  "component---src-pages-news-2024-12-17-data-seasonality-with-sprucely-io-js": () => import("./../../../src/pages/news/2024-12-17-data-seasonality-with-sprucely-io.js" /* webpackChunkName: "component---src-pages-news-2024-12-17-data-seasonality-with-sprucely-io-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-resources-connectors-js": () => import("./../../../src/pages/resources/connectors.js" /* webpackChunkName: "component---src-pages-resources-connectors-js" */),
  "component---src-pages-resources-examples-index-js": () => import("./../../../src/pages/resources/examples/index.js" /* webpackChunkName: "component---src-pages-resources-examples-index-js" */),
  "component---src-pages-resources-guides-business-intelligence-best-practices-maximizing-data-insights-js": () => import("./../../../src/pages/resources/guides/business-intelligence-best-practices-maximizing-data-insights.js" /* webpackChunkName: "component---src-pages-resources-guides-business-intelligence-best-practices-maximizing-data-insights-js" */),
  "component---src-pages-resources-guides-business-intelligence-maximizing-data-insights-with-dashboards-js": () => import("./../../../src/pages/resources/guides/business-intelligence-maximizing-data-insights-with-dashboards.js" /* webpackChunkName: "component---src-pages-resources-guides-business-intelligence-maximizing-data-insights-with-dashboards-js" */),
  "component---src-pages-resources-guides-dashboard-design-best-practices-js": () => import("./../../../src/pages/resources/guides/dashboard-design-best-practices.js" /* webpackChunkName: "component---src-pages-resources-guides-dashboard-design-best-practices-js" */),
  "component---src-pages-resources-guides-dashboard-effective-designs-js": () => import("./../../../src/pages/resources/guides/dashboard-effective-designs.js" /* webpackChunkName: "component---src-pages-resources-guides-dashboard-effective-designs-js" */),
  "component---src-pages-resources-guides-dashboard-effective-metrics-best-practices-js": () => import("./../../../src/pages/resources/guides/dashboard-effective-metrics-best-practices.js" /* webpackChunkName: "component---src-pages-resources-guides-dashboard-effective-metrics-best-practices-js" */),
  "component---src-pages-resources-guides-dashboards-data-analytics-maximizing-insights-js": () => import("./../../../src/pages/resources/guides/dashboards-data-analytics-maximizing-insights.js" /* webpackChunkName: "component---src-pages-resources-guides-dashboards-data-analytics-maximizing-insights-js" */),
  "component---src-pages-resources-guides-dashboards-for-business-insights-js": () => import("./../../../src/pages/resources/guides/dashboards-for-business-insights.js" /* webpackChunkName: "component---src-pages-resources-guides-dashboards-for-business-insights-js" */),
  "component---src-pages-resources-guides-dashboards-with-data-visualization-js": () => import("./../../../src/pages/resources/guides/dashboards-with-data-visualization.js" /* webpackChunkName: "component---src-pages-resources-guides-dashboards-with-data-visualization-js" */),
  "component---src-pages-resources-guides-dashboards-with-saas-increasing-efficiency-js": () => import("./../../../src/pages/resources/guides/dashboards-with-saas-increasing-efficiency.js" /* webpackChunkName: "component---src-pages-resources-guides-dashboards-with-saas-increasing-efficiency-js" */),
  "component---src-pages-resources-guides-excel-creating-a-dashboard-js": () => import("./../../../src/pages/resources/guides/excel-creating-a-dashboard.js" /* webpackChunkName: "component---src-pages-resources-guides-excel-creating-a-dashboard-js" */),
  "component---src-pages-resources-guides-excel-dashboard-templates-js": () => import("./../../../src/pages/resources/guides/excel-dashboard-templates.js" /* webpackChunkName: "component---src-pages-resources-guides-excel-dashboard-templates-js" */),
  "component---src-pages-resources-guides-excel-pm-dashboard-templates-js": () => import("./../../../src/pages/resources/guides/excel-pm-dashboard-templates.js" /* webpackChunkName: "component---src-pages-resources-guides-excel-pm-dashboard-templates-js" */),
  "component---src-pages-resources-guides-index-js": () => import("./../../../src/pages/resources/guides/index.js" /* webpackChunkName: "component---src-pages-resources-guides-index-js" */),
  "component---src-pages-resources-guides-powerpoint-creating-effective-dashboards-js": () => import("./../../../src/pages/resources/guides/powerpoint-creating-effective-dashboards.js" /* webpackChunkName: "component---src-pages-resources-guides-powerpoint-creating-effective-dashboards-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-resources-integration-index-js": () => import("./../../../src/pages/resources/integration/index.js" /* webpackChunkName: "component---src-pages-resources-integration-index-js" */),
  "component---src-pages-resources-rest-api-js": () => import("./../../../src/pages/resources/rest-api.js" /* webpackChunkName: "component---src-pages-resources-rest-api-js" */),
  "component---src-pages-resources-services-js": () => import("./../../../src/pages/resources/services.js" /* webpackChunkName: "component---src-pages-resources-services-js" */),
  "component---src-pages-resources-support-js": () => import("./../../../src/pages/resources/support.js" /* webpackChunkName: "component---src-pages-resources-support-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service/[...].js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-solutions-executive-management-dashboard-design-tips-js": () => import("./../../../src/pages/solutions/executive-management/dashboard-design-tips.js" /* webpackChunkName: "component---src-pages-solutions-executive-management-dashboard-design-tips-js" */),
  "component---src-pages-solutions-executive-management-dashboard-examples-js": () => import("./../../../src/pages/solutions/executive-management/dashboard-examples.js" /* webpackChunkName: "component---src-pages-solutions-executive-management-dashboard-examples-js" */),
  "component---src-pages-solutions-finance-assets-dashboard-examples-js": () => import("./../../../src/pages/solutions/finance/assets-dashboard-examples.js" /* webpackChunkName: "component---src-pages-solutions-finance-assets-dashboard-examples-js" */),
  "component---src-pages-solutions-general-features-and-benefits-js": () => import("./../../../src/pages/solutions/general/features-and-benefits.js" /* webpackChunkName: "component---src-pages-solutions-general-features-and-benefits-js" */),
  "component---src-pages-solutions-general-types-of-dashboards-js": () => import("./../../../src/pages/solutions/general/types-of-dashboards.js" /* webpackChunkName: "component---src-pages-solutions-general-types-of-dashboards-js" */),
  "component---src-pages-solutions-human-resources-dashboard-examples-js": () => import("./../../../src/pages/solutions/human-resources/dashboard-examples.js" /* webpackChunkName: "component---src-pages-solutions-human-resources-dashboard-examples-js" */),
  "component---src-pages-solutions-human-resources-people-analytics-dashboard-examples-js": () => import("./../../../src/pages/solutions/human-resources/people-analytics-dashboard-examples.js" /* webpackChunkName: "component---src-pages-solutions-human-resources-people-analytics-dashboard-examples-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-marketing-dashboard-examples-js": () => import("./../../../src/pages/solutions/marketing/dashboard-examples.js" /* webpackChunkName: "component---src-pages-solutions-marketing-dashboard-examples-js" */),
  "component---src-pages-solutions-medical-dashboard-examples-js": () => import("./../../../src/pages/solutions/medical/dashboard-examples.js" /* webpackChunkName: "component---src-pages-solutions-medical-dashboard-examples-js" */),
  "component---src-pages-solutions-project-management-dashboard-examples-js": () => import("./../../../src/pages/solutions/project-management/dashboard-examples.js" /* webpackChunkName: "component---src-pages-solutions-project-management-dashboard-examples-js" */),
  "component---src-pages-solutions-project-management-financials-dashboard-examples-js": () => import("./../../../src/pages/solutions/project-management/financials-dashboard-examples.js" /* webpackChunkName: "component---src-pages-solutions-project-management-financials-dashboard-examples-js" */),
  "component---src-pages-solutions-project-management-multiple-project-dashboard-examples-js": () => import("./../../../src/pages/solutions/project-management/multiple-project-dashboard-examples.js" /* webpackChunkName: "component---src-pages-solutions-project-management-multiple-project-dashboard-examples-js" */),
  "component---src-pages-solutions-sales-performance-dashboard-examples-js": () => import("./../../../src/pages/solutions/sales/performance-dashboard-examples.js" /* webpackChunkName: "component---src-pages-solutions-sales-performance-dashboard-examples-js" */),
  "component---src-pages-terms-service-js": () => import("./../../../src/pages/terms-service.js" /* webpackChunkName: "component---src-pages-terms-service-js" */)
}

